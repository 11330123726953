'use client';

import { useEffect, useState } from 'react';
import Link from 'next/link';
import { Input, Label, TextField } from 'react-aria-components';

import SanityImage from '@/_components/Global/SanityImage/SanityImage';
import { ALL_PEOPLE_QUERYResult } from '@/_lib/types';

type TeamDirectoryClientProps = {
  data: ALL_PEOPLE_QUERYResult;
};

export default function TeamDirectoryClient({ data }: TeamDirectoryClientProps) {
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch(document.location.hash.replace('#', ''));
  }, []);

  if (!data) {
    return null;
  }

  const filteredData = data
    .filter((d) => d.slug.current)
    .filter(
      (d) =>
        !search ||
        d.title.toLowerCase().includes(search) ||
        d.email.toLowerCase().includes(search) ||
        d.jobTitle.toLowerCase().includes(search) ||
        d.location.toLowerCase().includes(search),
    );

  return (
    <section>
      <TextField autoComplete="name" name="name" type="text" isRequired>
        <Label>Search:</Label>
        <Input
          className="rounded-sm border"
          onChange={(e) => {
            const value = e.target.value.trim().toLowerCase();
            setSearch(value);
            window.location.hash = value;
          }}
          value={search}
          type="search"
        />
      </TextField>

      {filteredData.length === 0 && <p>No team members match your search query.</p>}

      {filteredData.length > 0 && (
        <nav aria-label="Team directory">
          <ul className="m-0 grid list-none grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-8 p-0">
            {filteredData.map((d) => {
              return (
                <li key={d._id}>
                  <Link href={d.slug.current}>
                    <SanityImage
                      alt={`Profile image for ${d.title}`}
                      image={d.rollupImage}
                      sizes="(max-width: 48rem) 100vw, (max-width: 60rem) 33vw, 300px"
                      width={300}
                    />
                    <span>{d.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      )}
    </section>
  );
}
