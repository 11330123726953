'use client';

import Link from 'next/link';
import { useState } from 'react';
import { Form } from 'react-aria-components';
import { Field } from '@habanero-w9/ui';

import { PAGE_QUERY_FILTER_SLUGResult } from '@/_lib/types';
import { useActionState } from '@/_hooks/use-action-state';
import { sendContactEmail } from '@/_services/send-contact-email';
import { Button } from '@habanero-w9/ui';

export type ContactFormState = 'Initial' | 'Invalid' | 'Successful' | 'Failure';

type ContactFormProps = Extract<
  NonNullable<NonNullable<PAGE_QUERY_FILTER_SLUGResult>['pageBuilder']>[number],
  { _type: 'contactForm' }
>;

const COPY_BUTTON_DELAY = 3000;

export default function ContactForm({
  heading,
  ourEmailLabel,
  contactEmail,
  copyButton,
  copiedButton,
  nameLabel,
  nameRequiredMessage,
  emailLabel,
  emailRequiredMessage,
  messageLabel,
  messageRequiredMessage,
  sendButton,
  successPage,
}: ContactFormProps) {
  const [copied, setCopied] = useState(false);
  const [, submitAction, isPending] = useActionState<ContactFormState, FormData>(
    sendContactEmail,
    'Initial',
  );

  return (
    <section className="max-w-[400]">
      {heading && <h2>{heading}</h2>}

      {contactEmail && (
        <div>
          <span>{ourEmailLabel}:</span>
          <Link href={`mailto:${contactEmail}`}>{contactEmail}</Link>
          <button
            onClick={() => {
              navigator.clipboard.writeText(contactEmail);

              setCopied(true);
              setTimeout(() => setCopied(false), COPY_BUTTON_DELAY);
            }}
            type="button"
          >
            {copied ? copiedButton : copyButton}
          </button>
        </div>
      )}

      <Form action={submitAction}>
        <input name="redirectUrl" type="hidden" value={successPage?.slug.current} />

        <Field
          autoComplete="name"
          name="name"
          type="text"
          isRequired
          className="flex flex-col"
          label={nameLabel}
          errorMessage={nameRequiredMessage}
        />

        <Field
          autoComplete="email"
          name="email"
          type="email"
          isRequired
          className="flex flex-col"
          label={emailLabel}
          errorMessage={emailRequiredMessage}
        />

        <Field
          name="message"
          type="textarea"
          isRequired
          className="flex flex-col"
          label={messageLabel}
          errorMessage={messageRequiredMessage}
        />

        <Button type="submit" isDisabled={isPending}>
          {sendButton}
        </Button>
      </Form>
    </section>
  );
}
