'use client';

import Link from 'next/link';
import { useState } from 'react';

import { ALL_INSIGHTS_ORDERED_BY_DATEResult } from '@/_lib/types';
import { formatDate } from '@/_lib/format-date';
import SanityImage from '../../Global/SanityImage/SanityImage';
import { Input, Label, TextField } from 'react-aria-components';

type InsightsLightClientProps = {
  data: ALL_INSIGHTS_ORDERED_BY_DATEResult;
};

export default function InsightsListClient({ data }: InsightsLightClientProps) {
  const [search, setSearch] = useState('');

  if (!data) {
    return null;
  }

  const filteredData = data.filter(
    (d) =>
      !search ||
      d.title.toLowerCase().includes(search) ||
      d.tags?.map((t) => t.name.toLowerCase()).includes(search),
  );

  return (
    <section>
      <TextField autoComplete="name" name="name" type="text" isRequired>
        <Label>Search:</Label>
        <Input
          className="rounded-sm border"
          onChange={(e) => {
            const value = e.target.value.trim().toLowerCase();
            setSearch(value);
            window.location.hash = value;
          }}
          value={search}
          type="search"
        />
      </TextField>

      {filteredData.length === 0 && <p>No team members match your search query.</p>}

      <nav aria-label="Latest insights">
        <ul className="m-0 grid list-none grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-8 p-0">
          {filteredData.map((d) => {
            return (
              <li key={d._id} className="flex flex-col">
                <Link href={d.slug.current}>
                  <SanityImage
                    alt=""
                    image={d.rollupImage}
                    sizes="(max-width: 48rem) 100vw, (max-width: 60rem) 33vw, 300px"
                    width={300}
                  />
                  <span>{d.title}</span>
                </Link>
                <time dateTime={d.datePublished}>{formatDate(d.datePublished)}</time>
              </li>
            );
          })}
        </ul>
      </nav>
    </section>
  );
}
