import { jsx, jsxs } from 'react/jsx-runtime';
import classNames from 'classnames';
import { Button as Button$1, TextField, Label, TextArea, Input, Text, FieldError } from 'react-aria-components';

const Button = ({ className, ...props }) => {
    return (jsx(Button$1, { className: classNames(className, 'border border-black bg-transparent px-4 py-2 hover:cursor-pointer'), ...props }));
};

const Field = ({ description, errorMessage, label, placeholder, inputProps, type, ...props }) => {
    return (jsxs(TextField, { type: type, ...props, children: [label && jsx(Label, { children: label }), type === 'textarea' && (jsx(TextArea, { className: "border", placeholder: placeholder, ...inputProps })), type !== 'textarea' && (jsx(Input, { className: "border", placeholder: placeholder, ...inputProps })), description && jsx(Text, { slot: "description", children: description }), errorMessage && jsx(FieldError, { children: errorMessage })] }));
};

export { Button, Field };
