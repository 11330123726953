'use client';

import Link from 'next/link';
import { Tab, TabList, TabPanel, Tabs } from 'react-aria-components';

import { ALL_EVENTS_ORDERED_BY_DATEResult, EventList as EventListProps } from '@/_lib/types';
import { formatDateRange } from '@/_lib/format-date-range';
import SanityImage from '../../Global/SanityImage/SanityImage';

export default function EventListClient({
  data,
}: EventListProps & { data: ALL_EVENTS_ORDERED_BY_DATEResult }) {
  return (
    <Tabs className="flex flex-col gap-4">
      <TabList aria-label="Filter events by start date" className="flex gap-4">
        <Tab id="all">All</Tab>
        <Tab id="upcoming">Upcoming</Tab>
        <Tab id="past">Past</Tab>
      </TabList>
      <TabPanel id="all">
        <nav aria-label="Latest events">
          <ul className="m-0 grid list-none grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-8 p-0">
            {data.map((d) => {
              return (
                <li key={d._id} className="flex flex-col">
                  <Link href={d.slug.current}>
                    <SanityImage
                      alt=""
                      image={d.rollupImage}
                      sizes="(max-width: 48rem) 100vw, (max-width: 60rem) 33vw, 300px"
                      width={300}
                    />
                    <span>{d.title}</span>
                  </Link>
                  <time>{formatDateRange(d.startDate, d.endDate)}</time>
                </li>
              );
            })}
          </ul>
        </nav>
      </TabPanel>
      <TabPanel id="upcoming">
        <nav aria-label="Latest events">
          <ul className="m-0 grid list-none grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-8 p-0">
            {data
              .filter((d) => !d.isPast)
              .map((d) => {
                return (
                  <li key={d._id} className="flex flex-col">
                    <Link href={d.slug.current}>
                      <SanityImage
                        alt=""
                        image={d.rollupImage}
                        sizes="(max-width: 48rem) 100vw, (max-width: 60rem) 33vw, 300px"
                        width={300}
                      />
                      <span>{d.title}</span>
                    </Link>
                    <time>{formatDateRange(d.startDate, d.endDate)}</time>
                  </li>
                );
              })}
          </ul>
        </nav>
      </TabPanel>
      <TabPanel id="past">
        <nav aria-label="Latest events">
          <ul className="m-0 grid list-none grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-8 p-0">
            {data
              .filter((d) => d.isPast)
              .map((d) => {
                return (
                  <li key={d._id} className="flex flex-col">
                    <Link href={d.slug.current}>
                      <SanityImage
                        alt=""
                        image={d.rollupImage}
                        sizes="(max-width: 48rem) 100vw, (max-width: 60rem) 33vw, 300px"
                        width={300}
                      />
                      <span>{d.title}</span>
                    </Link>
                    <time>{formatDateRange(d.startDate, d.endDate)}</time>
                  </li>
                );
              })}
          </ul>
        </nav>
      </TabPanel>
    </Tabs>
  );
}
